<template>
  <PageWithLayout>
    <Search
      :tabData="viewModel.tabData"
      :searchDataList.sync="searchData.searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onUpdateTab="viewModel.onUpdateTab()"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="searchData.searchParams"
      :dataList="searchData.dataList"
      :checkboxOnlyOne.sync="viewModel.checkboxOnlyOne"
      :selectedDataList.sync="viewModel.selectedDataList"
      :paginationData="searchData.paginationData"
      :emptyDesc="emptyDesc"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <span class="txt_right">{{ searchData.responseDate }}</span>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="멤버십 추가"
          @onClickBtn="viewModel.onClicMembershipJoin()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
        <Button
          v-if="!viewModel.isShowRowCheck"
          btnSize="small"
          btnStyle="secondary_border"
          text="복사 데이터 선택"
          @onClickBtn="viewModel.onClickShowRowCheck()">
        </Button>
        <Button
          v-else
          btnSize="small"
          btnStyle="secondary_border"
          text="데이터 복사"
          :disabled="viewModel.selectedDataList.length < 1"
          @onClickBtn="viewModel.onClickCopyTable()">
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <MembershipPaymentDetailPopup
        v-if="viewModel.isMembershipPaymentDetailPopup"
        :data="viewModel.detailData"
        @onClickClose="viewModel.onClickCloseMembershipPaymentDetailPopup()"
        @onClickComplete="cancelData => viewModel.onClickCompeleteMembershipPaymentCancelPopup(cancelData)"
      />
      <MembershipPaymentCancelPopup
        v-if="viewModel.isMembershipPaymentCancelPopup"
        :data="viewModel.detailData"
        @onClickClose="viewModel.onClickCloseMembershipPaymentCancelPopup()"
        @onClickComplete="cancelData => viewModel.onClickCompeleteMembershipPaymentCancelPopup(cancelData)"
      />
      <MembershipJoinPopup
        v-if="viewModel.isMembershipJoinPopup"
        :joinData.sync="viewModel.joinModel"
        :isFixedUid="false"
        :membershipTypeList="viewModel.membershipTypeList"
        @onClickClose="viewModel.onClickCloseMembershipJoinPopup()"
        @onClickComplete="joinData => viewModel.onClickCompeletMembershipJoinPopup(joinData)"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// popup
import MembershipPaymentDetailPopup from '@/views/service/membership/payments/view/popup/MembershipPaymentDetailPopup'
import MembershipPaymentCancelPopup from '@/views/service/membership/payments/view/popup/MembershipPaymentCancelPopup'
import MembershipJoinPopup from '@/views/service/membership/payments/view/popup/MembershipJoinPopup'
// viewModel
import MembershipPaymentsListViewModel from '@/views/service/membership/payments/viewModel/MembershipPaymentsListViewModel'

export default {
  name: 'MembershipPaymentsList',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,
    MembershipPaymentDetailPopup,
    MembershipPaymentCancelPopup,
    MembershipJoinPopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  computed:{
    searchData(){
      return this.viewModel.searchData[this.viewModel.tabData.value];
    },
    isSearchDataList(){
      return this.searchData.searchDataList && this.searchData.searchDataList.length >0
    },
    emptyDesc(){
      if(this.searchData.searched && !this.searchData.hasData){
        if(this.isSearchDataList){
          return `${this.viewModel.valueText} '${this.viewModel.emptyDescValue}' 검색결과가 없습니다`;
        }else{
          return '구매내역이 없습니다'
        }
      }else{
        if(this.isSearchDataList){
          return `${this.viewModel.valueText}${this.viewModel.endWord} 검색해주세요`;
        }else{
          return '구매내역이 없습니다';
        }
      }
    }
  },
  data(){
    return{
      viewModel: new MembershipPaymentsListViewModel(),
    }
  },
}
</script>
<style lang="scss" scoped>
.board_comm::v-deep .tbl_comm table .txt_tbl .text_mark{padding:0 4px;background-color:rgba($PRIMARY_COLOR, .8);color:$CONTENT_TEXT}
.board_comm::v-deep .tbl_comm table .tc_end_red .txt_tbl span{color:$RED_COLOR}
.txt_right{display:inline-block;margin:17px 8px 0 0;color:#999;vertical-align:top}
</style>