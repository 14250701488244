import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertDateFormat, dateDiff } from "@lemontree-ai/lemontree-admin-common-front/utils/dateUtils";
import cancelModel from '@/views/service/membership/payments/model/CancelModel';
import joinModel from '@/views/service/membership/payments/model/JoinModel';
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'


import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class MembershipPaymentsListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'ALL', text:'전체' },
        { id: 'USER_ID', text:'퍼핀 회원 ID로 검색' },
        { id: 'MEMBERSHIP_ID', text:'멤버십 종류로 검색' },
        { id: 'PURCHASE_METHOD_TYPE', text:'결제타입으로 검색' },
      ],
      option:{
        isCount: false
      },
      value:'ALL'
    }
    this.valueText = '';
    this.endWord = '';
    this.apiPathData = '';
    this.searchData = {
      ALL:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      USER_ID:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'퍼핀 회원 ID',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      MEMBERSHIP_ID:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'멤버십 종류',
            type: 'Radio',
            dataList: [],
            // colorSelect:'membership_purchase_method_type',
            isAddDataListAll: false,
            maxWidth:"300px",
            isFull:true,
            id:'membership_id',
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      },
      PURCHASE_METHOD_TYPE:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'결제타입',
            type: 'Radio',
            dataList:'membership_purchase_method_type',
            // colorSelect:'membership_purchase_method_type',
            isAddDataListAll: false,
            maxWidth:"300px",
            isFull:true,
            id:'purchase_method_type',
            value:'',
          },
        ],
        searchParams : {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData : {
          totalCount:0,
          totalPage:0
        },
        dataList:[],
        responseDate:'',
      }
    }
    this.boardData = {
      title:'전체 구매내역',
      drawDataList: [
        {
          title:'퍼핀 회원<br>ID',
          width:'80px',
          value:'user_id',
          isAlignLeft: true,
        },
        // {
        //   title:'역할',
        //   width:'44px',
        //   value:'user_role',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'user_role'
        //   },
        //   class: {
        //     classColorFilter:{
        //       name:'convertIdToColor',
        //       dataListName:'user_role',
        //       prev:'fw_bold tc'
        //     }
        //   },
        // },
        {
          title:'회원이름',
          width:'62px',
          value:'name',
          isAlignLeft: true,
          isEllip: true
        },
        {
          title:'계정<br>상태',
          width:'50px',
          value:'user_status',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          class: { classColorFilter:{ name:'convertIdToColor', dataListName:'user_status_name', prev:'tc' } }
        },
        {
          title:'멤버십 기한',
          width:'160px',
          value:'',
          dateRangeValue:{
            format:'YYYY-MM-DD HH:mm:ss',
            startValue:'membership_start_date_time',
            endValue:'membership_end_date_time',
            betweenText:' ~ ',
            isEndPastDateToRed:true
          },
          isAlignLeft: true
        },
        {
          title:'멤버십<br>상태',
          width:'52px',
          value:'user_membership_status',
          filter:{
            name:'convertIdToText',
            value:'user_status_name'
          },
          class: { classColorFilter:{ name:'convertIdToColor', dataListName:'user_status_name', prev:'tc' } }
        },
        {
          title:'멤버십 ID',
          width:'66px',
          value:'user_membership_id',
          isAlignLeft: true,
        },
        {
          title:'멤버십 UID',
          width:'196px',
          value:'user_membership_uid',
          isAlignLeft: true,
        },
        // {
        //   title:'멤버십종류',
        //   width:'76px',
        //   value:'membership',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'membership_type'
        //   },
        // },
        // {
        //   title:'membership_id',
        //   width:'76px',
        //   value:'membership_id',
        //   // isAlignLeft: true,
        // },
        {
          title:'멤버십종류',
          width:'90px',
          value:'membership_title',
          // isAlignLeft: true,
        },
        {
          title:'자동결제옵션',
          width:'88px',
          value:'membership_subscription_type',
          // isAlignLeft: true,
        },
        {
          title:'결제타입',
          width:'74px',
          value:'purchase_method_type',
          // isAlignLeft: true,
          filter:{
            name:'convertIdToText',
            value:'membership_purchase_method_type'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'membership_purchase_method_type'
            },
            badgeFill: true
          }
        },
        {
          title:'결제상태',
          width:'64px',
          value:'payment_status',
          filter:{
            name:'convertIdToText',
            value:'membership_payment_status'
          },
          class: { classColorFilter:{ name:'convertIdToColor', dataListName:'membership_payment_status', prev:'tc' } }
        },
        {
          title:'결제금액',
          width:'72px',
          value:'amount',
          filter: { name:'convertNumberToComma', value:'',value2:'0원',value3:'원' },
          isMobileTitle:true,
          isAlignRight: true,
        },
        {
          title:'결제일시',
          width:'160px',
          value:'purchased_at',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isMobileTitle:true,
          isAlignLeft: true,
        },
        // {
        //   title:'구매금액',
        //   width:'78px',
        //   value:'',
        //   isAlignRight: true,
        //   valueCustom:[
        //     {
        //       type: 'value',
        //       value:'amount',
        //       filter:{
        //         name:'convertNumberToComma',
        //       },
        //       emptyValueText: '0',
        //     },
        //     { 
        //       type: 'text',
        //       value: '원'
        //     },
        //   ],
        // },
        // {
        //   title:'환불금액',
        //   width:'80px',
        //   value:'',
        //   isAlignRight: true,
        //   valueCustom:[
        //     {
        //       type: 'value',
        //       value:'cancel_amount',
        //       filter:{
        //         name:'convertNumberToComma',
        //       },
        //       emptyValueText: '0',
        //     },
        //     { 
        //       type: 'text',
        //       value: '원'
        //     },
        //   ],
        // },
        // {
        //   title:'구매취소',
        //   width:'92px',
        //   value:'',
        //   button: { text:'구매취소', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickMembershipPaymentCancel(data) },
        // },
      ],
      option:{
        isTotal: true,
        isSize: true,
        rowIdValue: 'user_membership_uid'
      },
      markData:[
        {
          id : '',
          value : ''
        }
      ]
    };
    this.checkboxOnlyOne = false;
    this.isShowRowCheck = false;
    this.checkboxData ={
      title:'',
      width:'42px',
      value:'',
      isCheckbox:true
    }
    this.selectedDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.isMembershipPaymentDetailPopup = false;
    this.isMembershipPaymentCancelPopup = false;
    this.isMembershipJoinPopup = false;
    this.detailData = {};
    this.emptyDescValue = '';
    this.cancelModel = new cancelModel();
    this.joinModel = new joinModel();
  }
  onClickShowRowCheck(){
    this.isShowRowCheck = true;
    this.boardData.drawDataList.unshift(this.checkboxData);
  }
  onClickCopyTable(){
    var myCopyArea = document
      .getElementsByClassName('tbl_body')[0]
      .getElementsByTagName('tbody')[0];
    var rows = myCopyArea.getElementsByTagName("tr");
    let copyDataList = [];
    Array.from(rows).forEach(rowItem => {
      var rowKeyName = this.boardData.option.rowIdValue || 'id';
      var isSelectRow = this.selectedDataList.find(selectRowItem => rowItem.outerText.indexOf(selectRowItem[rowKeyName]) >= 0);
      if(isSelectRow){
        var rowText = rowItem.outerText.slice(1);
        copyDataList.push(rowText);
      }
    });
    const copyData = copyDataList.join('\n');
    window.navigator.clipboard.writeText(copyData).then(() => {
      store.dispatch('commonToast/fetchToastStart', '테이블데이터가 복사되었습니다');
      this.resetCheckCopy();
    });
  }
  resetCheckCopy(){
    if(this.isShowRowCheck){
      this.boardData.drawDataList.shift();
    }
    this.checkboxOnlyOne = false;
    this.isShowRowCheck = false;
    this.selectedDataList = [];
  }

  // 상세
  onClickRow(rowId,rowData){
    this.detailData = rowData;
    this.isMembershipPaymentDetailPopup = true;
  }
  onClickCloseMembershipPaymentDetailPopup(){
    this.isMembershipPaymentDetailPopup = false;
    this.detailData = {};
  }
  // 구매취소
  onClickMembershipPaymentCancel(data){
    this.detailData = data;
    this.isMembershipPaymentCancelPopup = true;
  }
  onClickCloseMembershipPaymentCancelPopup(){
    this.isMembershipPaymentCancelPopup = false;
    this.detailData = {};
  }
  onClickCompeleteMembershipPaymentCancelPopup(data){
    this.cancelModel.setId(this.detailData.user_membership_id);
    this.cancelModel.setData(data);
    this.postMembershipPaymentCancel();
  }
  // 멤버십 등록
  onClicMembershipJoin(){
    this.isMembershipJoinPopup = true;
  }
  onClickCloseMembershipJoinPopup(){
    this.isMembershipJoinPopup = false;
    this.joinModel = new joinModel();
  }
  onClickCompeletMembershipJoinPopup(data){
    this.joinModel.setData(data);
    this.postMembershipJoin();
  }
  onUpdateTab(){
    this.setTabData()
  }
  setTabData(){
    let markId = '';
    const searchDataList = this.searchData[this.tabData.value].searchDataList;
    const isSearchDataList = searchDataList && searchDataList.length > 0;
    let value = isSearchDataList ? searchDataList[0].value : '';
    this.emptyDescValue = value;
    switch(this.tabData.value){
      case 'ALL' : { 
        this.valueText = '전체';
        this.endWord = '를';
        this.apiPathData = apiPath.MEMBERSHIP_PAYMENTS_LIST;
        markId = '';
        value = '';
        break
      }
      case 'USER_ID' : { 
        this.valueText = '퍼핀 회원 ID';
        this.endWord = '를';
        this.apiPathData = apiPath.MEMBERSHIP_PAYMENTS_USER_ID;
        markId = 'user_id';
        value = Number(value);
        break
      }
      case 'MEMBERSHIP_ID' : { 
        this.valueText = '멤버십 종류';
        this.endWord = '를';
        this.apiPathData = apiPath.MEMBERSHIP_PAYMENTS_MEMBERSHIP_ID;
        markId = '';
        value = '';
        this.emptyDescValue = convertIdToText(this.emptyDescValue,this.membershipTypeList);
        break
      }
      case 'PURCHASE_METHOD_TYPE' : { 
        this.valueText = '결제타입';
        this.endWord = '을';
        this.apiPathData = apiPath.MEMBERSHIP_PAYMENTS_PURCHASE_METHOD_TYPE;
        markId = '';
        value = '';
        this.emptyDescValue = convertIdToText(this.emptyDescValue,'membership_purchase_method_type');
        break
      }
    }
    const tabText = this.tabData.dataList.find(item => item.id === this.tabData.value).text;
    let allBoardTitle = '전체 구매내역';
    this.boardData.title = isSearchDataList ? `${tabText}결과`: allBoardTitle;
    this.boardData.markData[0].id = markId;
    this.boardData.markData[0].value = value;

    this.membershipTypeList = [];
  }
  init(){
    this.setTabData();
    this.getData()
    this.getMembershipTypeList();
  }
  onSearch(){
    this.getData();
    this.resetCheckCopy();
  }
  getData(){
    this.searchData[this.tabData.value].searched = false;
    const searchDataList = this.searchData[this.tabData.value].searchDataList;
    const isSearchDataList = searchDataList && searchDataList.length > 0;
    let value = '';
    const query = makeQueryStringByObject(this.searchData[this.tabData.value].searchParams);
    let path = `${this.apiPathData}${query}`;
    if(isSearchDataList){
      value = this.searchData[this.tabData.value].searchDataList[0].value;
      this.searchData[this.tabData.value].searchedValue = value;
      if(!value && isSearchDataList){
        store.dispatch('commonToast/fetchToastStart', `${this.valueText}${this.endWord} 검색해주세요`);
        return
      }
      path = `${this.apiPathData.format(value)}${query}`;
    }
    this.setTabData();
    GET_REQUEST(path).then(
    (success) => {
      this.searchData[this.tabData.value].searched = true;
      const resultData = success.data;
      this.searchData[this.tabData.value].dataList = resultData.list;
      this.searchData[this.tabData.value].paginationData.totalCount = resultData.total;
      this.searchData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
      this.searchData[this.tabData.value].responseDate = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
      this.searchData[this.tabData.value].hasData = resultData.total > 0;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postMembershipPaymentCancel(){
    const path = `${apiPath.MEMBERSHIP_PAYMENTS_CANCEL}`;
    const data = this.cancelModel.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      this.isMembershipPaymentDetailPopup = false;
      this.isMembershipPaymentCancelPopup = false;
      this.detailData = {};
      store.dispatch('commonToast/fetchToastStart', '멤버십 구매가 취소되었습니다');
      this.getData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postMembershipJoin(){
    const path = `${apiPath.MEMBERSHIP_JOIN}`;
    const data = this.joinModel.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      this.isMembershipJoinPopup = false;
      store.dispatch('commonToast/fetchToastStart', '멤버십이 부여되었습니다');
      this.joinModel = new joinModel();
      this.getData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 멤버십 타입 목룍
  getMembershipTypeList(){
    let path = `${apiPath.MEMBERSHIP_ID_LIST}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      const membershipTypeList = resultData.memberships.map(item => {
        const name = item.name;
        const title = item.title;
        return {
          id: item.id,
          text: name === title ? title : name+' '+title
        };
      });
      this.membershipTypeList = membershipTypeList;
      this.searchData.MEMBERSHIP_ID.searchDataList[0].dataList = membershipTypeList
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}